@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
// @import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
// @import "~@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import '~@ng-select/ng-select/themes/material.theme.css';
/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
*/

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* percentage of user browser font-size setting */

  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-color);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  margin: 0;
  color: #606060;
}

* {
  box-sizing: border-box;
}

%heading-style {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 300;
}

h6 {
  @extend %heading-style;
}

h5 {
  @extend %heading-style;
}

h4 {
  @extend %heading-style;
}

h3 {
  @extend %heading-style;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  color: var(--navy-text);
  @extend %heading-style;
}

h1 {
  @extend %heading-style;
}

p {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--grey-text);
  margin-top: 0;

  a {
    color: var(--blue-text);
  }
}

.mat-divider {
  border-color: var(--dark-border);
}

.mat-sort-header-arrow {
  color: var(--grey-text);
}

.mat-mdc-menu-content,
.mat-mdc-card,
.mat-mdc-menu-panel,
.mat-expansion-panel,
.mat-autocomplete-panel,
.mat-mdc-dialog-container {
  background-color: var(--white-bg);
  // padding: 16px;
}
.mat-mdc-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.container {
  max-width: 114rem;
  margin: 0 auto;
  // background-color: var(--white-bg);
}

.container-fluid {
  max-width: 100%;
}

.link {
  font-size: 1.6rem;
  font-weight: 700;
  color: #f6921e;
  text-decoration: none;
  cursor: pointer;
}

.learn-link {
  font-size: 1.8rem;
}

.landing-icon {
  max-width: 4rem;
  margin-bottom: 2.4rem;
}

.img-arrow {
  max-width: 2.5rem;
}

.mat-mdc-card {
  color: inherit;
  line-height: 1.2;
  background-color: var(--card-bg);
}

.mat-mdc-card-actions {
  &.custom-card-footer {
    border-top: 1px solid var(--dark-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-title {
  margin-top: 0;
}

.landing-section {
  .mat-mdc-card {
    text-align: center;
  }

  .mat-mdc-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    height: 100%;
  }
}

mat-sidenav.mat-drawer.mat-drawer-side {
  transition: all 250ms ease-in-out;
  z-index: 1000;
  background-color: var(--side-nav);
}

.no-sidenav {
  .mat-drawer-container {
    &.main-content {
      background-color: var(--white-bg);
    }
  }
}

.mat-drawer-container {
  &.main-content {
    position: absolute;
    top: 6.5rem;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--bg-color);
    border-top: 1px solid var(--dark-border);

    &::after {
      content: "";
      position: absolute;
      height: 0.6rem;
      width: 100%;
      top: -0.6rem;
      left: 0;
      // box-shadow: 0px 3px 6px var(--box-shadow);
      z-index: 999;
    }

    .mat-sidenav-content:not(.no-padding) {
      padding: 0 5rem;
    }
  }

  .mat-drawer {
    overflow: initial;

    .mat-drawer-inner-container {
      overflow: initial;
    }
  }
}

.text-center {
  text-align: center;
}

.mat-mdc-dialog-container {
  min-width: 50rem;
  border-radius: 0 !important;
  box-shadow: 0 1.1rem 1.5rem -0.7rem rgba(0, 0, 0, 0.2),
    0 2.4rem 3.8rem 0.3rem rgba(0, 0, 0, 0.14),
    0 0.9rem 4.6rem 0.8rem rgba(0, 0, 0, 0.12);
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.mat-mdc-dialog-content {
  margin-top: 1.5rem !important;
}

.mat-drawer {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

.pagetoggle {
  .navi-text {
    display: none;
  }
}

// .mat-sidenav {
//   background-color: var(--side-nav);
//   z-index: 100;
// }

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #014b76;
}

.flex-inline {
  display: inline-flex;
}

mat-icon {
  cursor: pointer;
}

body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-card-header-02 {
  margin: 0;
  .mat-mdc-card-header-text {
    margin: 0;
    .mat-mdc-card-title-02 {
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1.5;
    }
  }
}
.bold {
  font-weight: 500;
}
.dashboard-card {
  padding-bottom: 3rem !important;
  .mat-mdc-card-header {
    .mat-mdc-card-title {
      margin-bottom: 2.5rem;
    }
  }
}

.text-grey {
  color: #606060;
}

.blur-text {
  color: transparent !important;
  text-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.26);

  &::before,
  &::after {
    filter: blur(0.5rem);
  }
}

#ok {
  color: #009933;
}

#warning {
  color: #e07700;
}

#error {
  color: #cc0000;
}

div,
li:focus {
  outline: none;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #e3750c !important;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e3750c;
}

.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e3750c;
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  color: #e3750c;
  background-color: #e3750c;
}

.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #e3750c;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
  word-break: break-all !important;
  text-align: center;
}
.ng-select .ng-select-container {
  background-color: var(--input-bg) !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-color: var(--mat-menu-item-border) !important;
  background-color: var(--input-bg) !important;
  color: var(--accent-btn-text) !important;
  &:hover {
    background-color: var(--mat-menu-item-hover) !important;
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--mat-menu-item-hover) !important;
}

.success-snackbar {
  background-color: green;
}

.error-snackbar {
  background-color: red;
}
.ng-select .ng-select-container {
  color: var(--dark-text);
}

.shepherd-button {
  &.green-button {
    background: green;
    color: white;
    border-radius: 1rem;
  }
}

.shepherd-element,
.shepherd-arrow {
  background-color: #024b76 !important;
}

.shepherd-arrow:before {
  background-color: #024b76 !important;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background-color: #024b76 !important;
}

.shepherd-title {
  color: #fff !important;
  font-weight: bold !important;
}

.shepherd-text {
  color: #fff !important;
}

:host ::ng-deep .mat-mdc-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--loader) !important;
}

::ng-deep .cytoscape-navigator {
  display: none;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 1.6rem;
  background-color: var(--white-bg) !important;
  color: var(--dark-text);
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: unset !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: var(--dark-text) !important;
}

table {
  background-color: var(--white-bg) !important;
}

td.mat-mdc-cell {
  color: var(--grey-text) !important;
  border: 1px solid var(--border) !important;
}

.mat-toolbar {
  min-height: 6.5rem !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: unset !important;
}

.mdc-tab__content .mdc-tab__text-label {
  color: var(--dark-text) !important;
}

.mat-datepicker-content-container {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;

  .mat-calender {
    color: var(--dark-text) !important;
  }
}

.mat-calendar-table {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

.mat-calendar {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

.mat-calendar-content {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

.mat-calendar-body-cell {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

.mat-calendar-body-cell-content {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

::ng-deep .mat-calendar-header {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

.mat-calendar-body-selected {
  background-color: var(--white-bg) !important;
  color: var(--dark-text) !important;
}

.mat-calendar-controls {
  color: var(--dark-text) !important;
}

.mat-calendar-period-button {
  color: var(--dark-text) !important;
}

/* Change the navigation buttons (previous/next month arrows) */
.mat-calendar-previous-button,
.mat-calendar-next-button {
  color: var(--dark-text) !important;
}

/* Ensure calendar title (month and year) remains white */
.mat-calendar-header .mat-calendar-period-button {
  color: var(--dark-text) !important;
}

.mat-calendar-table thead th {
  color: var(--dark-text) !important;
}

.mat-calendar-body-label {
  color: var(--dark-text) !important;
}
