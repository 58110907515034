:root {
  --navy: #014b76;
  --orange: #f6921e;
  --green: #4caf50;
}

body {
  --side-nav: #014b76;
  --spinner: #014b76;
  --navy-bg: #014b76;
  --dark-bg: #014b76;
  --progress: #004182;
  --loader: #014b76;
  --splash: #014b76;
  --bg-color: #f5f5f5;
  --input-bg: #fff;
  --input-border: #d8d8d8;
  --input-outline: #a8a7a7;
  --navbar: #fff;
  --box-shadow: #a1a9b79c;
  --btn-box-shadow: #a1a9b79c;
  --active-link: #8cbcdc;
  --active-link-text: #014b76;
  --modal-text: #636363;
  --link: #636363;
  --white-bg: #fff;
  --dark-text: #014b76;
  --navy-text: #014b76;
  --blue-text: #0584ce;
  --link-text: #0584ce;
  --grey-text: #606060;
  --light-grey: #717889;
  --grayscale-text: #000;
  --black-text: #000;
  --primary-btn: #014b76;
  --accent-btn: #e2eaef;
  --secondary-btn: #f5921d;
  --outline-btn: #93b0c0;
  --white-btn: #fff;
  --white-contrast-btn: #fff;
  --grey-btn: #ececec;
  --light-btn: #e3ebf0;
  --light-btn-text: #6793ad;
  --view-toggle-btn: #fff;
  --accent-btn-text: #6793ad;
  --action-btn: #f1f4f6;
  --mat-menu-item-border: #f5f5f5;
  --mat-menu-item-hover: rgba(0, 0, 0, 0.04);
  --navy-card: #014b76;
  --grey-card: #ececed;
  --chart-card: #fff;
  --metrics-card: #eff5f8;
  --calculations-card: #eaf4fe;
  --calculations-card-modal: #eaf4fe;
  --expansion-panel-header: #e2eaef;
  --expansion-panel-header-text: #787878;
  --table-header: #8cbcdc63;
  --table-header-text: #787878;
  --table-cell-text: #707070;
  --even-table-row: #f5f5f5;
  --odd-table-row: #fff;
  --table-pagination-text: #707070;
  --table-row-hover: #c2d8f0;
  --disabled-btn-text: rgba(0, 0, 0, 0.26);
  --border: #f5f5f5;
  --blue-border: #c1d2dd;
  --dark-border: #dedede;
  --light-mode-only-border: #c4c1c1;
  --card-bg: #f1f4f6;
  --card-icon: #f6921e;
  --details-bg: #e8e8e8;
  --details-fg: #f8f8f8;
  --label: #636363;
  --chbx-frame: rgba(0, 0, 0, 0.54);
  --table-footer: #e8e8e8;
  --rdbtn-thumb: #014b76;
  --rdbtn-bar: rgba(1, 75, 118, 0.7);
  --navy-bg-icon: #fff;
  --tree-item: #000000;
  --tree-selected-background: #b2e1fd;
  --inventory-main-title: #626262;
  --inventory-key: #989898;
  --inventory-value: #0e6a9f;
}

body.dark-theme {
  --side-nav: #262626;
  --spinner: #f6921e;
  --navy-bg: #b6acac;
  --dark-bg: #fff;
  --loader: #8cbcdc;
  --progress: #f6921e;
  --splash: #1c1c21;
  --bg-color: #1c1c21;
  --input-bg: #1c1c21;
  --input-border: #1c1c21;
  --input-outline: #b6acac;
  --navbar: #262626;
  --box-shadow: #8585859c;
  --btn-box-shadow: #00000029;
  --modal-text: #d3d3d3;
  --link: #99bee6;
  --active-link: #515151;
  --active-link-text: #99bee6;
  --white-bg: #262626;
  --dark-text: #fff;
  --navy-text: #99bee6;
  --blue-text: #99bee6;
  --link-text: #fff;
  --grey-text: #d3d3d3;
  --light-grey: #d3d3d3;
  --grayscale-text: #fff;
  --black-text: #a1a9b7;
  --primary-btn: #f6921e;
  --secondary-btn: #f6921e;
  --outline-btn: #fff;
  --accent-btn: #1c1c21;
  --white-btn: #1c1c21;
  --white-contrast-btn: #d3d3d3;
  --grey-btn: #1c1c21;
  --light-btn: #d3d3d3;
  --light-btn-text: #000;
  --view-toggle-btn: #bbc0c4;
  --accent-btn-text: #bbc0c4;
  --action-btn: #d3d3d3;
  --mat-menu-item-border: #262626;
  --mat-menu-item-hover: #65676c;
  --navy-card: #424246;
  --grey-card: #424246;
  --chart-card: #1c1c21;
  --metrics-card: #282e35;
  --calculations-card: #54657b;
  --calculations-card-modal: #262626;
  --expansion-panel-header: #1c1c21;
  --expansion-panel-header-text: #99bee6;
  --table-header: #5b6c7f;
  --table-header-text: #bbc0c4;
  --table-cell-text: #bbc0c4;
  --even-table-row: #262626;
  --odd-table-row: #1c1c21;
  --table-pagination-text: #bbc0c4;
  --table-row-hover: #3a4047;
  --disabled-btn-text: rgb(174 157 157 / 22%);
  --border: #373636;
  --blue-border: #b6acac;
  --dark-border: #373636;
  --light-mode-only-border: transparent;
  --card-bg: #262626;
  --card-icon: #99bee6;
  --details-bg: #47484b;
  --details-fg: #1c1c21;
  --label: #99bee6;
  --chbx-frame: #a3a6b4;
  --table-footer: #262626;
  --rdbtn-thumb: #99bee6;
  --rdbtn-bar: rgba(153, 190, 230, 0.7);
  --navy-bg-icon: #014b76;
  --tree-item: #b6bbbf;
  --tree-selected-background: #5e8da9;
  --inventory-main-title: #fafafa;
  --inventory-key: #e8e8e8;
  --inventory-value: #b2b6ba;
  --mat-datepicker-toggle-icon-color: #fff;
  --mdc-elevated-card-container-color: var(--white-bg);
  --mdc-filled-button-container-color: var(--input-bg);
  --mdc-filled-button-label-text-color: var(--gray-text);
  --mat-select-panel-background-color: var(--white-bg);
}
body * {
  transition: color 200ms, background-color 200ms;
}
